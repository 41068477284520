@import '../../../../../styles/customMediaQueries.css';

.priorityLinkWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.priorityLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  background: #C8814D;
  width: 190px;
  height: 40px;

  align-items: center;
  display: flex;
  justify-content: center;

  flex-shrink: 0;
  padding: 0 12px 0 12px;
  margin: 0;
  

  &:hover {
    /* border-bottom: 4px solid var(--marketplaceColor); */
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

/* .highlight {
  color: var(--marketplaceColor);
  &:hover {
    color: var(--marketplaceColorDark);
  }
} */

.priorityLinkLabel {
  composes: textSmall from global;
  display: inline-block;
  text-decoration: inherit;
  text-wrap: nowrap;
  color: #FFF;
  text-align: center;
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 1px;
}
